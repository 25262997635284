// Medium devices (tablets, 768px and up)

@media (min-width: 768px) { 
    .main-wrapper{
        .left-sidebar{
            // left:-300px
        }
        .page-wrapper{
            padding-left: 0px;
        }
    }
 }

 @media (min-width: 1024px) { 
    .main-wrapper {
        .page-wrapper {
            padding-left: 240px;
        }
    }
    
}

 @media (max-width: 767.98px) {
     .gallery-length{position: relative;
    padding: 3px 15px;
    font-weight: 400;
    color: #777;
    text-align: center;
    background-color: #fff4e5de;
    border-radius: 20px;
    left: 26px;
    z-index: 999;
    top: -30px;
}
   .mail-badge {
       position: static;
   }
    .main-wrapper{
        .sidebar-show{
            visibility: hidden!important;
            margin-left: -400px!important;
        }
        .sidebar-hide {
            visibility: visible!important;
            margin-left: 0px!important;
        }
        .left-sidebar{
            // left:-300px
        }
        .page-wrapper{
            padding-left: 0px;
        }  
    }
    /********Mobile Screen Only********/
    header{
        .navbar-nav{
            .dropdown.show.nav-item{
                position: static;
            }
            .dropdown-menu {
                position: absolute;
                width: 100%; 
                right: 0;
                left: auto;
            }
        }
        .m-collapse{ 
            position: absolute;
            top: 70px;
            right: 0;
            background-color: $white;
            border-bottom: solid 1px $gray-300; 
            z-index: 999;
            min-width: 320px;
            width: 100%; 
            .navbar-nav{
                justify-content: flex-end;
                flex-direction: row;
                .nav-item{
                    .nav-link{  
                        position: relative;
                    }
                }
            } 
        }
    }  
 }